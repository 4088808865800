import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import type { JWTTokensStore } from '../types'

export const useJWTTokensStore = create<JWTTokensStore>()(
    persist(
        (set) => ({
            access: '',
            refresh: '',
            setAccess: (access: string) => set({ access }),
            setRefresh: (refresh: string) => set({ refresh })
        }),
        { name: 'tokens' }
    )
)
