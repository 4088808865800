import { TelegramAuthForm } from '@features/auth-by-telegram'
import { Card, Layout, Typography } from 'antd'

const { Title } = Typography

export const LoginPage = () => {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Card style={{ margin: 'auto' }}>
                <Title level={4}>Пожалуйста, авторизуйтесь</Title>
                <TelegramAuthForm />
            </Card>
        </Layout>
    )
}
