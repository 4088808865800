import { login, refreshTokens } from '@shared/auth/api'
import type { LoginSchema, RefreshSchema } from '@shared/auth/api/types'
import { useAuthStore } from '@shared/auth/model/store/auth-store'
import { useJWTTokensStore } from '@shared/auth/model/store/jwt-tokens'
import { getErrorMessage } from '@shared/lib/errors'
import { message } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

export const useAuth = () => {
    const [error, setError] = useState('')

    const {
        refresh: rToken,
        setAccess,
        setRefresh
    } = useJWTTokensStore(useShallow(({ refresh, setAccess, setRefresh }) => ({ refresh, setAccess, setRefresh })))
    const { isAuth, isLoading, setIsAuth, setIsLoading } = useAuthStore(
        useShallow(({ isAuth, isLoading, setIsAuth, setIsLoading }) => ({ isAuth, isLoading, setIsAuth, setIsLoading }))
    )

    const onAuthSuccess = useCallback(
        (access: string, refresh: string) => {
            setAccess(access)
            setRefresh(refresh)
            setIsAuth(true)
        },
        [setAccess, setRefresh, setIsAuth]
    )

    const loginByTelegram = useCallback(
        async (data: LoginSchema) => {
            try {
                const { refresh, access } = await login(data)
                onAuthSuccess(access, refresh)
            } catch (error) {
                message.error(getErrorMessage(error))
            }
        },
        [onAuthSuccess]
    )

    const refreshToken = useCallback(
        async (data: RefreshSchema) => {
            try {
                const { access } = await refreshTokens(data)
                setAccess(access)
                setIsAuth(true)
                setIsLoading(false)
            } catch (error) {
                setError(getErrorMessage(error))
                setIsAuth(false)
            } finally {
                setIsLoading(false)
            }
        },
        [setAccess, setIsAuth, setIsLoading]
    )

    useEffect(() => {
        if (isLoading) {
            refreshToken({ refresh: rToken })
        }
    }, [])

    return {
        isAuth,
        error,
        isLoading,
        loginByTelegram
    }
}
