import { useCurrentUser } from '@entities/user/model/hooks/use-current-user'
import type { MenuProps } from 'antd'
import { Avatar, Dropdown, Flex, Layout, Space, theme } from 'antd'
import { memo, useMemo } from 'react'
import { Link } from 'react-router-dom'

const { Header } = Layout

export const Navbar = memo(() => {
    const {
        token: { colorBgContainer }
    } = theme.useToken()

    const { user, logout } = useCurrentUser()

    const items: MenuProps['items'] = useMemo(
        () => [
            {
                key: '1',
                label: <Link to="/profile">Профиль</Link>
            },
            {
                key: '4',
                danger: true,
                label: 'Выйти',
                onClick: () => logout()
            }
        ],
        [logout]
    )

    return (
        <Header style={{ padding: 0, background: colorBgContainer }}>
            <Flex justify="end" style={{ margin: '0 16px' }}>
                <Dropdown trigger={['click']} menu={{ items }}>
                    <Space>
                        <Avatar src={user?.photoUrl}></Avatar>
                        <span>{`${user?.firstName} ${user?.lastName}`}</span>
                    </Space>
                </Dropdown>
            </Flex>
        </Header>
    )
})
