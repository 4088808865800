import { App as AntdApp } from 'antd'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from './providers/error-boundary'
import { AppRouter } from './providers/router'
import { ThemeProvider } from './providers/theme-provider'
import './styles/index.scss'

export const App = () => {
    return (
        <BrowserRouter>
            <ThemeProvider>
                <AntdApp>
                    <ErrorBoundary>
                        <AppRouter />
                    </ErrorBoundary>
                </AntdApp>
            </ThemeProvider>
        </BrowserRouter>
    )
}
