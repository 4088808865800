import { Layout, Menu, MenuProps } from 'antd'
import { FileOutlined, MessageOutlined } from '@ant-design/icons'
import { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const { Sider } = Layout

type MenuItem = Required<MenuProps>['items'][number]

interface SidebarProps {}

export const Sidebar = memo((props: SidebarProps) => {
    const [collapsed, setCollapsed] = useState(false)
    const navigate = useNavigate()

    const items: MenuItem[] = [
        { label: 'Пункт меню 1', key: '1', onClick: () => navigate('/') },
        { label: 'Профиль', key: '2', onClick: () => navigate('/profile') },
        {
            label: 'Постинг',
            key: 'sub1',
            icon: <MessageOutlined />,
            children: [
                { label: 'Черновики', key: '3', icon: <FileOutlined /> },
                { label: 'Запланированные', key: '4' }
            ]
        }
    ]

    return (
        <Sider theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
            <div className="logo">Логотип</div>
            <Menu items={items} mode="inline" defaultSelectedKeys={['1']} />
        </Sider>
    )
})
