import { ConfigProvider } from 'antd'
import React from 'react'

interface ThemeProviderProps {
    children: JSX.Element
}

export function ThemeProvider({ children }: ThemeProviderProps) {
    return (
        <ConfigProvider
            theme={{
                token: {
                    // Seed Token
                    // colorPrimary: '#00b96b',
                    borderRadius: 8
                    // Alias Token
                    // colorBgContainer: '#f6ffed'
                }
            }}
        >
            {children}
        </ConfigProvider>
    )
}
