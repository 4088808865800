import { useJWTTokensStore } from '@shared/auth'
import axios from 'axios'

export const httpClient = axios.create({
    baseURL: '/api'
})

httpClient.interceptors.request.use(
    (config) => {
        const access = useJWTTokensStore.getState().access

        config.headers.Authorization = access ? `Bearer ${access}` : ''

        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

httpClient.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        return Promise.reject(error)
    }
)
