import { httpClient } from '@shared/api'
import type { LoginResponse, LoginSchema, LogoutSchema, RefreshResponse, RefreshSchema } from './types'

export const login = async (data: LoginSchema) => {
    const { data: result } = await httpClient.post<LoginResponse>('/auth/login/', data)
    return result
}

export const logout = async (data: LogoutSchema) => {
    return httpClient.post<void>('/auth/logout/', data)
}

export const refreshTokens = async (data: RefreshSchema) => {
    const { data: result } = await httpClient.post<RefreshResponse>('/auth/refresh/', data)
    return result
}
