import { useAuth } from '@features/auth-by-telegram/model/hooks/use-auth'
import { useEffect, useRef } from 'react'

interface TelegramUser {
    id: number
    first_name: string
    last_name: string
    username: string
    photo_url: string
    auth_date: number
    hash: string
}

export const TelegramAuthForm: React.FC = () => {
    const divRef = useRef<HTMLDivElement | null>(null)
    const { loginByTelegram } = useAuth()

    useEffect(() => {
        if (divRef.current) {
            const script = document.createElement('script')
            script.src = 'https://telegram.org/js/telegram-widget.js?22'
            script.setAttribute('data-telegram-login', 'evgeniyvlasov_bot')
            script.setAttribute('data-size', 'large')
            script.setAttribute('data-onauth', 'onTelegramAuth(user)')
            script.setAttribute('data-request-access', 'write')
            script.async = true

            divRef.current.appendChild(script)
            ;(window as any).onTelegramAuth = (user: TelegramUser) => {
                loginByTelegram({
                    id: user.id,
                    firstName: user.first_name,
                    lastName: user.last_name,
                    username: user.username,
                    photoUrl: user.photo_url,
                    authDate: user.auth_date,
                    hash: user.hash
                })
            }

            return () => {
                if (divRef.current) {
                    divRef.current.innerHTML = ''
                }
                delete (window as any).onTelegramAuth
            }
        }
    }, [])

    return (
        <div>
            <div ref={divRef} />
        </div>
    )
}
