import { theme } from 'antd'

interface MainPageProps {}

export const MainPage = (props: MainPageProps) => {
    const {
        token: { colorBgContainer, borderRadiusLG }
    } = theme.useToken()

    return (
        <div
            style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG
            }}
        >
            Таблица с постами
        </div>
    )
}
