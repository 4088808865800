import { Button, Flex, Result } from 'antd'

export const PageError = () => {
    const reloadPage = () => {
        // eslint-disable-next-line no-restricted-globals
        location.reload()
    }

    return (
        <Flex align="center" justify="center" style={{ height: '100vh' }}>
            <Result
                status="500"
                title="500"
                subTitle="Произошла непредвиденная ошибка."
                extra={
                    <Button onClick={reloadPage} type="primary">
                        Перезагрузить
                    </Button>
                }
            />
        </Flex>
    )
}
