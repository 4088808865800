import { Navbar } from '@widgets/navbar'
import { Sidebar } from '@widgets/sidebar'
import { Breadcrumb, Layout } from 'antd'

const { Content, Footer } = Layout

interface PageTemplateProps {
    children: React.JSX.Element
}

export const PageTemplate = ({ children }: PageTemplateProps) => {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sidebar />
            <Layout>
                <Navbar />
                <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb
                        items={[
                            {
                                key: '1',
                                title: 'Постинг'
                            },
                            {
                                key: '2',
                                title: 'Черновики'
                            }
                        ]}
                        style={{ margin: '16px 0' }}
                    />
                    {children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Ant Design ©{new Date().getFullYear()} Created by Ant UED
                </Footer>
            </Layout>
        </Layout>
    )
}
