import { isAxiosError } from 'axios'
import type { AxiosError } from 'axios'

export function getErrorMessage(error: unknown) {
    if (isAxiosError(error)) {
        return (error as AxiosError<{ detail: string }>).response?.data?.detail || 'Непредвиденная ошибка'
    } else {
        return 'Непредвиденная ошибка'
    }
}
