import { useAuth } from '@features/auth-by-telegram/model/hooks/use-auth'
import { LoginPage } from '@pages/login-page'
import { MainPage } from '@pages/main-page'
import { ProfilePage } from '@pages/profile-page'
import { PageTemplate } from '@widgets/page-template'
import { Spin } from 'antd'
import { useEffect, useMemo } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

export const AppRouter = () => {
    const { isAuth, isLoading } = useAuth()

    const routes = useMemo(
        () => [
            {
                path: '/',
                element: (
                    <PageTemplate>
                        <MainPage />
                    </PageTemplate>
                )
            },
            {
                path: '/profile',
                element: (
                    <PageTemplate>
                        <ProfilePage />
                    </PageTemplate>
                )
            },
            { path: '/login', element: <LoginPage /> }
        ],
        []
    )

    const navigate = useNavigate()

    useEffect(() => {
        if (isLoading) return
        if (!isAuth) {
            navigate('/login')
        } else {
            navigate('/')
        }
    }, [isLoading, isAuth, navigate])

    return (
        <Spin spinning={isLoading} fullscreen={isLoading}>
            <Routes>
                {routes.map(({ element, path }) => (
                    <Route key={path} path={path} element={element} />
                ))}
            </Routes>
        </Spin>
    )
}
