import { theme } from 'antd'

interface ProfilePageProps {}

export const ProfilePage = (props: ProfilePageProps) => {
    const {
        token: { colorBgContainer, borderRadiusLG }
    } = theme.useToken()

    return (
        <div
            style={{
                padding: 24,
                minHeight: 360,
                background: colorBgContainer,
                borderRadius: borderRadiusLG
            }}
        >
            Профиль
        </div>
    )
}
