import { getUser } from '@entities/user/api'
import { useJWTTokensStore } from '@shared/auth'
import { logout } from '@shared/auth/api'
import { useAuthStore } from '@shared/auth/model/store/auth-store'
import { useCallback, useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { useUserStore } from '../store/user'

export const useCurrentUser = () => {
    const { isAuth, setIsAuth } = useAuthStore()
    const { refresh, setRefresh } = useJWTTokensStore(
        useShallow(({ refresh, setRefresh }) => ({ refresh, setRefresh }))
    )
    const { user, setUser, setIsLoading } = useUserStore(
        useShallow(({ user, setUser, setIsLoading }) => ({ user, setUser, setIsLoading }))
    )

    const onLogout = useCallback(() => {
        setRefresh('')
        setIsAuth(false)
        setUser(null)
    }, [setRefresh, setUser, setIsAuth])

    const getCurrentUser = useCallback(async () => {
        try {
            const user = await getUser()
            setUser(user)
        } finally {
            setIsLoading(false)
        }
    }, [setUser, setIsLoading])

    const logoutHandler = useCallback(async () => {
        try {
            await logout({ refresh })
            onLogout()
        } catch (error) {
            console.log(error)
        }
    }, [onLogout, refresh])

    useEffect(() => {
        if (isAuth) {
            getCurrentUser()
        }
    }, [isAuth, getCurrentUser])

    return {
        user,
        logout: logoutHandler
    }
}
